import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/modern/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();
import { Vue3Toasity } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

// Firebase authentication
import { initFirebaseBackend } from './authUtils';
// Axios to connect api
import axios from 'axios'
// Element UI import
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/en';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MEASUREMENTID
};

initFirebaseBackend(firebaseConfig);



AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});
// Set the locale for ElDatePicker to Spanish
const app = createApp(App)
    .use(Vue3Toasity)
    .use(store)
    .use(VueApexCharts)
    .use(ElementPlus, { locale })
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside)
    .use(router);



app.config.globalProperties.axios=axios

const baseURL = process.env.VUE_APP_BASE_URL;
if (typeof baseURL !== 'undefined') {
    axios.defaults.baseURL = baseURL;
  }
  
  // Add a request interceptor to set the 'x-api-key' header
  axios.interceptors.request.use((config) => {
    // Modify the 'x-api-key' header value here
    const apiKey = process.env.VUE_APP_BASE_URL_APIKEY; // Replace with your actual API key
    config.headers['x-api-key'] = apiKey;
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  
  app.config.globalProperties.axios = axios;

app.mount('#app')